<template>
    <section class="GalleryGrid">
        <div class="GalleryGrid__row">
            <div class="GalleryGrid__item" ref="gridItem1">
                <figure>
                    <SmartImage
                        class="GalleryGrid__image"
                        :url="slice.primary.landscape_image_1.url || ''"
                        :width="
                            slice.primary.landscape_image_1?.dimensions
                                ?.width || 1950
                        "
                        :height="
                            slice.primary.landscape_image_1?.dimensions
                                ?.height || 1170
                        "
                        :srcs="[
                            { width: 400 },
                            { width: 800 },
                            { width: 1200 },
                        ]"
                        :as-bg="true"
                        :alt="slice.primary.landscape_image_1.alt || ''"
                        positioning="relative"
                    />
                    <figcaption
                        v-if="slice.primary.landscape_image_1.alt"
                        class="GalleryGrid__item-caption"
                    >
                        {{ slice.primary.landscape_image_1.alt }}
                    </figcaption>
                </figure>
            </div>
            <div class="GalleryGrid__item" ref="gridItem2">
                <figure>
                    <SmartImage
                        class="GalleryGrid__image"
                        :url="slice.primary.landscape_image_2.url"
                        :width="
                            slice.primary.landscape_image_2?.dimensions
                                ?.width || 1950
                        "
                        :height="
                            slice.primary.landscape_image_2?.dimensions
                                ?.height || 1170
                        "
                        :srcs="[
                            { width: 400 },
                            { width: 800 },
                            { width: 1200 },
                        ]"
                        :as-bg="true"
                        :alt="slice.primary.landscape_image_2.alt"
                        positioning="relative"
                    />
                    <figcaption
                        v-if="slice.primary.landscape_image_2.alt"
                        class="GalleryGrid__item-caption"
                    >
                        {{ slice.primary.landscape_image_2.alt }}
                    </figcaption>
                </figure>
            </div>
        </div>
        <div class="GalleryGrid__row">
            <div class="GalleryGrid__item GalleryGrid__item--3" ref="gridItem3">
                <figure>
                    <SmartImage
                        class="GalleryGrid__image"
                        :url="slice.primary.landscape_image_3.url"
                        :width="
                            slice.primary.landscape_image_3?.dimensions
                                ?.width || 1950
                        "
                        :height="
                            slice.primary.landscape_image_3?.dimensions
                                ?.height || 1170
                        "
                        :srcs="[
                            { width: 400 },
                            { width: 800 },
                            { width: 1200 },
                        ]"
                        :as-bg="true"
                        :alt="slice.primary.landscape_image_3.alt"
                        positioning="relative"
                    />
                    <figcaption
                        v-if="slice.primary.landscape_image_3.alt"
                        class="GalleryGrid__item-caption"
                    >
                        {{ slice.primary.landscape_image_3.alt }}
                    </figcaption>
                </figure>
            </div>
            <div class="GalleryGrid__col">
                <div
                    v-if="slice.primary.portrait_image_1.url"
                    class="GalleryGrid__item"
                    ref="gridItem4"
                >
                    <figure>
                        <SmartImage
                            :url="slice.primary.portrait_image_1.url"
                            :width="
                                slice.primary.portrait_image_1?.dimensions
                                    ?.width || 1950
                            "
                            :height="
                                slice.primary.portrait_image_1?.dimensions
                                    ?.height || 1170
                            "
                            :srcs="[
                                { width: 400 },
                                { width: 800 },
                                { width: 1200 },
                            ]"
                            :as-bg="true"
                            :alt="slice.primary.portrait_image_1.alt"
                            positioning="relative"
                        />
                        <figcaption
                            v-if="slice.primary.portrait_image_1.alt"
                            class="GalleryGrid__item-caption"
                        >
                            {{ slice.primary.portrait_image_1.alt }}
                        </figcaption>
                    </figure>
                </div>
                <div
                    v-if="slice.primary.portrait_image_2.url"
                    class="GalleryGrid__item"
                    ref="gridItem5"
                >
                    <figure>
                        <SmartImage
                            :url="slice.primary.portrait_image_2.url"
                            :width="
                                slice.primary.portrait_image_2?.dimensions
                                    ?.width || 1950
                            "
                            :height="
                                slice.primary.portrait_image_2?.dimensions
                                    ?.height || 1170
                            "
                            :srcs="[
                                { width: 400 },
                                { width: 800 },
                                { width: 1200 },
                            ]"
                            :as-bg="true"
                            :alt="slice.primary.portrait_image_1.alt"
                            positioning="relative"
                        />
                        <figcaption
                            v-if="slice.primary.portrait_image_2.alt"
                            class="GalleryGrid__item-caption"
                        >
                            {{ slice.primary.portrait_image_2.alt }}
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
defineProps(
    getSliceComponentProps<Content.GalleryGridSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const gridItem1 = ref()
const gridItem2 = ref()
const gridItem3 = ref()
const gridItem4 = ref()
const gridItem5 = ref()
const galleryGridObserver = ref()

onMounted(() => {
    galleryGridObserver.value = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('GalleryGrid__item--in-view')
                }
            })
        },
    )

    gridItem1.value && galleryGridObserver.value.observe(gridItem1.value)
    gridItem2.value && galleryGridObserver.value.observe(gridItem2.value)
    gridItem3.value && galleryGridObserver.value.observe(gridItem3.value)
    gridItem4.value && galleryGridObserver.value.observe(gridItem4.value)
    gridItem5.value && galleryGridObserver.value.observe(gridItem5.value)
})
</script>

<style lang="scss">
.GalleryGrid {
    grid-column: 2 / span 22;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 8rem;

    @include large-up {
        margin-top: 11.25rem;
    }
}

.GalleryGrid__col,
.GalleryGrid__row {
    display: flex;
    column-gap: 1rem;
}

.GalleryGrid__row {
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    @include large-up {
        flex-direction: row;
    }
}

.GalleryGrid__col,
.GalleryGrid__item {
    position: relative;
    display: flex;
    flex-basis: 50%;
}

.GalleryGrid__item {
    overflow: hidden;

    figure {
        display: flex;
        width: 100%;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--white);
        transform: translateX(0);
        transition: transform 0.7s ease-out;
    }
}

.GalleryGrid__item--in-view {
    &:after {
        transform: translateX(100%);
    }
}

.GalleryGrid__item--3 {
    flex-basis: 55%;
}

.GalleryGrid__image {
    width: 100%;
}

.GalleryGrid__item-caption {
    display: none;

    @include medium-up {
        @include body-sm;

        display: block;
        position: absolute;
        bottom: 0;
        background-color: var(--lime-green);
        z-index: 1;
        color: var(--black);
        padding: 0.25rem 0.75rem;
        font-style: italic;
    }
}
</style>
